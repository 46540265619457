/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const getRows = (data, handleAcreditar) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: item.acreditacion ? getUproColor("uproGreen") : getUproColor("uproYellow"),
                }}
                fontSize="lg"
              >
                {item.diplomaEntregado ? "school" : "history_edu"}
              </Icon>
            }
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip
                title={
                  item?.acreditacion
                    ? item?.diplomaEntregado
                      ? "Diploma entregado"
                      : `Acreditado ${dayjs(item.acreditacion).format("DD/MM/YYYY HH:mm")}`
                    : "Sin acreditar"
                }
                placement="top"
              >
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: item.acreditacion
                      ? getUproColor("uproGreen")
                      : getUproColor("uproYellow"),
                  }}
                  fontSize="lg"
                >
                  {item.diplomaEntregado ? "school" : "history_edu"}
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        posicion: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              <SoftBox
                sx={{
                  cursor: "pointer",
                }}
                display="flex"
                justifyContent="end"
              >
                {item?.acreditacion ? (
                  <SoftBox display="flex" alignItems="center" justifyContent="center" p={1}>
                    <Icon
                      sx={{
                        color: getUproColor("uproGreen"),
                      }}
                      fontSize="medium"
                    >
                      {item?.diplomaEntregado ? "done_all" : "done"}
                    </Icon>
                  </SoftBox>
                ) : (
                  <SoftButton
                    color="uproYellow"
                    circular
                    onClick={() => handleAcreditar(item.id)}
                    iconOnly
                  >
                    <Icon>arrow_forward</Icon>
                  </SoftButton>
                )}
              </SoftBox>
            }
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item.acreditacion ? item?.orden : "-"}
            </SoftTypography>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.apellido
                ? makeNiceText(item?.apellido + " " + item?.nombre, "nombre")
                : item?.username
            }
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {makeNiceText(item?.apellido + " " + item?.nombre, "nombre")}
              {item?.esDiscapacitado && (
                <Tooltip title="Discapacitado" placement="top">
                  <Icon color="info" sx={{ verticalAlign: "middle", ml: 0.5 }} fontSize="small">
                    accessible
                  </Icon>
                </Tooltip>
              )}
            </SoftTypography>
          </SoftBox>
        ),
        dni: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.dni}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.dni}
            </SoftTypography>
          </SoftBox>
        ),
        carrera: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.carrera}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {item?.carrera}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            display="flex"
            justifyContent="end"
          >
            {item?.acreditacion ? (
              <Tooltip
                title={item?.diplomaEntregado ? "Diploma entregado" : "Acreditado"}
                placement="top"
              >
                <SoftBox display="flex" alignItems="center" justifyContent="center" p={1} gap={1}>
                  <SoftTypography
                    variant="button"
                    fontWeight="bold"
                    color="uproGreen"
                    sx={{
                      textWrap: "nowrap",
                    }}
                  >
                    {item?.diplomaEntregado ? "Diploma entregado" : "Acreditado"}
                  </SoftTypography>
                  <Icon
                    sx={{
                      color: getUproColor("uproGreen"),
                    }}
                    fontSize="medium"
                  >
                    {item?.diplomaEntregado ? "done_all" : "done"}
                  </Icon>
                </SoftBox>
              </Tooltip>
            ) : (
              <Tooltip title="Acreditar" placement="top">
                <SoftButton color="uproYellow" circular onClick={() => handleAcreditar(item.id)}>
                  Acreditar&nbsp;
                  <Icon>arrow_forward</Icon>
                </SoftButton>
              </Tooltip>
            )}
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "posicion", desc: "Posición", width: "1%", align: "center" },
  { name: "nombre", desc: "Nombre", align: "left", orderField: "nombreCompleto" },
  { name: "dni", desc: "DNI", align: "left" },
  { name: "carrera", desc: "Carrera", align: "left" },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
